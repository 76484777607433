<template>
  <div class="pageMain">
    <search-head :model="searchForm" @search="search" @reset="reset">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="角色名称">
          <el-input
            v-model="searchForm.roleName"
            size="small"
            placeholder="角色名称"
          ></el-input>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
      :data="tableData"
      :headerCellStyle="headerCellStyle"
      @selectionChange="handleSelectionChange"
      @handleCurrentChange="handleCurrentChange"
                   @handleSizeChange="handleSizeChange"
    
      :total="total"
      title="角色列表"
    >
      <template slot="btns">
        <el-button
        type="success"
          size="small"
          @click="addFrom"
          icon="el-icon-plus"
          class="addBtn"
          >新增</el-button
        >
      </template>
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="roleName" label="角色名称" align="center">
      </el-table-column>
      <el-table-column prop="roleCode" align="center" label="角色编码">
      </el-table-column>
      <el-table-column prop="isPreset" align="center" label="是否预置">
        <template slot-scope="scope">
          <span v-if="scope.row.isPreset == 0">是</span>
          <span v-if="scope.row.isPreset == 1">否</span>
        </template>
      </el-table-column>

      <el-table-column prop="roleState" align="center" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.roleState == 0" type="success">启用</el-tag>
          <el-tag v-if="scope.row.roleState == 1" type="warning">停用</el-tag>
          <el-tag v-if="scope.row.roleState == 2" type="danger">删除</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="roleNote" align="center" label="备注说明">
      </el-table-column>
      <el-table-column prop="createTime" align="center" label="添加时间">
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span @click="detailItem(scope.row)" class="detail">
            <i class="el-icon-edit"></i>
          </span>
          <span @click="deleteItem(scope.row)" class="detail delete">
            <i class="el-icon-delete"></i>
          </span>
        </template>
      </el-table-column>
    </custom-tables>

    <custom-dialog
      :visible.sync="showDialog"
      width="500px"
      :title="dialogTitle"
      @close="colseDialog"
      @submit="editsubmit"
    >
      <el-form
        :model="editForm"
        label-width="80px"
        class="edit-form"
        :rules="rules"
        ref="roleFormRef"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input
            size="small"
            v-model="editForm.roleName"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色编码" prop="roleCode">
          <el-input
            size="small"
            v-model="editForm.roleCode"
            placeholder="请输入角色编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否预置" prop="isPreset">
          <el-select
            class="block-select"
            size="small"
            v-model="editForm.isPreset"
            placeholder="请选择是否预置"
          >
            <el-option
              v-for="item in presetState"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色状态" prop="roleState">
          <el-select
            class="block-select"
            size="small"
            v-model="editForm.roleState"
            placeholder="请选择角色状态"
          >
            <el-option
              v-for="item in roleState"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注说明" prop="roleNote">
          <el-input
            size="small"
            v-model="editForm.roleNote"
            placeholder="请输入备注说明"
          ></el-input>
        </el-form-item>
      </el-form>
    </custom-dialog>
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import CustomDialog from "../../../components/common/CustomDialog";
import sysManage from "../../../api/modules/sysManage";
import { roleState, presetState } from "../../../config/dataStatus";

export default {
  name: "index",
  components: { CustomDialog, CustomTables, SearchHead },
  data() {
    return {
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      editForm: {
        roleName: "",
        isPreset: 1,
        roleCode: "",
        roleNote: "",
        roleState: 0,
      },
      total: 0,
      showDialog: false,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      dialogTitle: "",
      rules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        roleCode: [
          { required: true, message: "请输入角色编码", trigger: "blur" },
        ],
        isPreset: [{ required: true, message: "请选择是否预置", trigger: "change" }],
        roleState: [{ required: true, message: "请选择角色状态", trigger: "change" }],
      },
      presetState: presetState,
      roleState: roleState,
    };
  },
  mounted() {
    this.getList();
    

    
  },
  methods: {
    getList() {
      sysManage.getListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getList();
    },
    addFrom() {
      this.editForm = {};
      this.showDialog = true;
      this.dialogTitle = "角色新增";
    },
    colseDialog() {
      this.showDialog = false;
      this.getList();
    },
    submit() {
      this.showDialog = false;
    },
    detailItem(row) {
      //详情
      this.editForm = row;
      this.dialogTitle = "编辑角色信息";
      this.showDialog = true;
    },
    deleteItem(item) {
      this.$confirm(`此操作将永久删除该${item.roleName}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sysManage.deleteRole({ roleId: item.roleId }).then((res) => {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editsubmit() {
      this.$refs.roleFormRef.validate((valid) => {
        if (valid) {
          if (this.editForm.roleId) {
            // 编辑
            sysManage.editRole(this.editForm).then((res) => {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.getList();
              this.showDialog = false;
            });
          } else {
            sysManage.addRole(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.getList();
              this.showDialog = false;
            });
          }
        }
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
    handleSelectionChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped lang="less">
.detail {
  color: #008f4d;
}
.delete {
  margin-left: 10px;
}
.addBtn {
  background-color: #008f4d;
}
</style>
